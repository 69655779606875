import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container,Navbar,Nav, Tabs, Tab } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import favImg03 from "../../../images/fav-img03.jpg"
import favImg04 from "../../../images/fav-img04.jpg"
//import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import $ from "jquery"

const MgFavouritesLocalLife=(props)=>{
   
    const [key, setKey] = useState('restaurant-and-bars');

    var all_cat = [];
    for(var i=0; i < props.category.length; i++){
      all_cat.push(props.category[i].node)
    }

    function uni(data, key) {
      return [
        ...new Map(
          data.map(x => [key(x), x])
        ).values( )
      ]
    }

    const cat = uni(all_cat,it=>it.strapiId)

  return (
    <section className="mg-favourites">
      <Container>
        {cat.map((data, i) => {
        const category = data.URL

        var res_list = [];
        for(var i=0; i < props.res.length; i++){
        if(props.res[i].node.Category.URL === category) {
          res_list.push(props.res[i].node)
        }
                  
        }

        const count_1 = res_list.length
        var co_type = "odd"
        if(count_1 % 2 == 0) {
          co_type = "even";
        }
          return <>
          {data.local_life_things_to_dos.length != 0 &&
          <div className="block">
          {/* <ScrollAnimation animateIn='fadeInUp' animateOnce> */}
          <Row>
            <Col md={8}>
                <h2 className="sec-title">{data.Name}</h2>
                {data.Alias === 'restaurant-and-bars' &&
                  <ReactMarkdown source={props.data.Area_Local_Loving_Life.Restaurants_and_Bars_Intro} allowDangerousHtml />
                }
                {data.Alias === 'shops' &&
                  <ReactMarkdown source={props.data.Area_Local_Loving_Life.Shops_Intro} allowDangerousHtml />
                }
                {data.Alias === 'clubs-and-societies' &&
                  <ReactMarkdown source={props.data.Area_Local_Loving_Life.Clubs_and_Societies_Intro} allowDangerousHtml />
                }
                {data.Alias === 'events' &&
                  <ReactMarkdown source={props.data.Area_Local_Loving_Life.Events_Intro} allowDangerousHtml />
                }
            </Col>
            <Col md={4}>
              <div className="view-more">
                <Link to={`../../../${data.URL}/${props.data.URL}`} className="btn">View More</Link>
              </div>
            </Col>
          </Row> 

          <div className="tab-content">
          <Row className="mg-things-do-list">
          {res_list.map((node, i) => {
            return <>
              <Col xl={6}>
                  <Link to={`/about/loving-life/${node.Category.URL}/${node.URL}`} className="link">
                      <div className={`favourite-item d-flex ${count_1 == i+1 ? 'no-border' : ''} ${co_type == "even" ? 'no-border' : ''}`}>
                          <figure>
                              {node.Tile_Image ? <img loading="lazy" src={node.Tile_Image.childImageSharp && node.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} /> : '' }
                              {node.Tile_Image_Label ? <span className="tag-abs text-uppercase">{node.Tile_Image_Label}</span> : '' }
                          </figure>
                          <div className="info">
                              <h4 className="info-title">{node.Name}</h4>
                              <span className="add-txt">{node.Address?.substring(0, 50)} {node.Address?.length > 50 ? '...' : ''}</span>
                              <ReactMarkdown source={node.Short_Description} allowDangerousHtml className=""/>
                          </div>
                      </div>
                  </Link>
              </Col>
            </>;
          })}
          </Row>
          </div>
          {/* </ScrollAnimation> */}
          </div>
          }
          </>;
        })}

      </Container>
    </section>
  );
  }

export default MgFavouritesLocalLife
